import config from "../config.json"

export default new class Order {

    /**
     * This action is responsible for fetching information
     * from the upstream API about single order using the
     * order ref. This will directly return the information
     */
    get(ref) {
        return new Promise((resolve, reject) => {
            fetch(config.api.url + "/v1.0/order/" + ref, {
                method: "GET",
                headers: { "Authorization": "Bearer " + localStorage.getItem("token") }
            }).then(Respose => Respose.json()).then(Response => {
                if ( Response.status == "success" ) {
                    resolve(Response.data.order)
                } else { ( Response.status == "failed" ) ? reject(Response.error) : reject(false) }
            }).catch(error => reject(error))
        })
    }

}