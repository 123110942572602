<template>
	<div class="mx-auto">
		<div class="grid grid-cols-12 h-screen">
			<div class="col-span-2 flex flex-col text-white left">
				<Sidebar />
			</div>
			<div class="col-span-1 p-4">
				{{ order }}
			</div>
		</div>
	</div>
</template>

<script>
import Sidebar from "../../components/Sidebar.vue"

// Services
import Order from "@/services/order"

export default {
	name: "Order",
	components: { Sidebar },

    data: () => ({
        order: {}
    }),

    mounted() {
        Order.get(this.$route.params.ref).then(Order => { this.order = Order }).catch(error => console.log(error))
    }
}
</script>

<style scoped>
.left {
	background-color: #4338CA;
}
</style>
